import { Injectable } from '@angular/core';

import { WebhookSubscription } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class WebhookSubscriptionsService extends GenericObjectsService<WebhookSubscription> {
  protected baseUrl = '/webhook-subscriptions';
}
